import React from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core';
import ItemIcon from '../CommonComponents/ItemIcon';
import EditButton from '../EditItems/EditButton';
import { substanceNameDetailToEditItem, classificationNameDetailToEditItem } from '../domain/EditDataTranslator';
import { Suggestion } from "../store/search/types";
import SwapVertIcon from '@material-ui/icons/SwapVert';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import LockIcon from "@material-ui/icons/Lock";
import { Paper, Button, Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  cell: {
    fontSize: 12,
    display: "flex",
    alignContent: "center"
  },
  tableheight: {
    paddingTop: 2,
    overflow: "auto",
    //minHeight: "70vh",
    height: "857px",
  },
  tableheightComp: {
    paddingTop: 2,
    overflow: "auto",
    //minHeight: "70vh",
    maxHeight: "70vh",
  },
  blur: {
    pointerEvents: "none",
    opacity: 0.4,
  },
  bold: {
    fontWeight:"bold"
  },
  container: {
    padding: 8,
    textAlign: 'left' as const,
    color: "gray"
  },
  tooltipText: {
    fontSize: 14,
    padding: 5,
    margin: 5,
    backgroundColor: "white",
    border: "1px solid black",
    color: "black",
  },
  domainLock: {
    fontSize: 24,
    color: "red",
    fontWeight: "bold",
    marginTop: 5,
    marginRight: "5%",
  },

  smallLock: {
    fontSize: 18,
    color: "red",
    fontWeight: "bold",
    marginTop: 4,
    justifyContent: "center",
    alignItems: "center",
    width: 16,
    height: 16,
  },
});

interface NameDetailListProps {
  data: any;
  highligted?: Suggestion | null;
  type?: any;
  itemDetailDataArray?: any;
  itemDetailParentVariantArray?: any;
  variantFlag?: any;
  polymerFlag?: any;
}

const NameDetailList = (props: NameDetailListProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const highlighted = props.highligted || { substanceId: "none", name: "none" };
  const substanceStatus = props.highligted ? props.highligted.status : null;
  const shouldHighlight = props.data.backboneIdentity === highlighted.substanceId;
  localStorage.setItem('allSubNameDetails', JSON.stringify(props.itemDetailDataArray));
  // console.log("props.type =",props.type)
  // scroll the highlighted element into the view
  const [backboneIcon, setBackboneIcon] = React.useState(false);

  const [showLockIcon, setShowLockIcon] = React.useState(true);

  //const backboneIcon : any = [];
  //React.useEffect(() => {
  //  for (let i = 0; i < props.itemDetailDataArray.length; i++) {
  //    if (props.data.backboneIdentity === props.itemDetailDataArray[i].name) {
  //      backboneIcon.push(props.itemDetailDataArray[i].name);
  //      //break;
  //    } else {
  //      //setBackboneIcone(false);
  //    }
  //  }
  //})
  //console.log(props.itemDetailDataArray);

  const highlightedRef = React.useCallback((element: HTMLElement | null) => {
    if (element) {
      element.scrollIntoView(false);
    }
  }, []);

  return (props.data && (
    <div className={props.type !== "Composite" ? classes.tableheight : classes.tableheightComp}>
      
      <Table stickyHeader aria-label="sticky table" >
        <TableHead>
          <TableRow>
            {props.type === 'Composite' &&  props.data && props.data.isLockIconRestricted && <TableCell></TableCell>}
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Domains</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="namelistBody">
          {props.itemDetailDataArray.map((item: any, index: any) => (
            <TableRow key={index}
              {...shouldHighlight && item.name === highlighted.name ? { ref: highlightedRef, selected: true } : {}}>
              {/* <TableCell>{props.type === "Composite" && showLockIcon ? (
                <Tooltip title="Restricted Substance">
                  <LockIcon style={{ fontSize: 16, color: 'red', fontWeight: 'bold', marginTop: 4, marginRight: "5%" }} />
                </Tooltip>
              ) : null}</TableCell> */}
              {props.type === 'Composite' && props.data && props.data.isLockIconRestricted && (
                <TableCell>{item.nameType === "SIR ID" ? (
                  <Tooltip title="Restricted Substance" classes={{ tooltip: classes.tooltipText }}>
                  <LockIcon className={classes.smallLock} />
               </Tooltip>
                ) : null}</TableCell>
              )}

              <TableCell>
                <Typography className={classes.cell}>
                  <ItemIcon type="Substance" subType={item.nameType} isBackbone={item.name === props.data.backboneIdentity ? true : false} />
                  {item.nameType}
                </Typography>
              </TableCell>
              <TableCell>{item.name}</TableCell>
              {
                item.isPrimaryForDomains && item.isPrimaryForDomains.length > 0 ?
                  <TableCell><span className={classes.bold}>{item.isPrimaryForDomains.join(", ")}</span>
                    {item.domains.filter((val: any) => !item.isPrimaryForDomains.includes(val)).join(", ") ? <span>,&nbsp;</span>
                      : null
                    }
                    <span>{item.domains.filter((val: any) => !item.isPrimaryForDomains.includes(val)).join(", ")}</span>
                  </TableCell> :
                  <TableCell>{item.domains.join(", ")}</TableCell>
              }
              <TableCell>{item.status}</TableCell>

              {// <TableCell className={substanceStatus === 'I' ? classes.blur : ''}>{item.nameType !== "SIR ID" && <EditButton itemData={substanceNameDetailToEditItem(item)} />}</TableCell>
              }
              <TableCell className={substanceStatus === 'I' ? classes.blur : ''}>{item.nameType !== "CLS ID" && item.nameType !== "SIR ID" && <EditButton itemData={props.type === 'Classification' ? classificationNameDetailToEditItem(item) : substanceNameDetailToEditItem(item)} />}</TableCell>
              {// <TableCell>{(item.nameType !== "SIR ID" && substanceStatus !== 'I') && <SwapHorizIcon />}</TableCell>
              }
              {
                //<TableCell>
                //{item.nameType !== "SIR ID" ? item.status !== "I" ? (item.isPrimaryForDomains && item.isPrimaryForDomains.length === 0) ? <SwapHorizIcon /> : null : null : null}</TableCell>
              }
            </TableRow>

          ))}
        </TableBody>
      </Table>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      {(props.itemDetailParentVariantArray.length > 0 && props.variantFlag == true && props.polymerFlag == true ? <div><div> <Paper className={classes.container}>
        PARENT DETAILS :
      </Paper> </div>
        <Table stickyHeader aria-label="sticky table" >
          <TableHead>
            <TableRow>
              {/* <TableCell></TableCell> */}
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Domains</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="namelistBody">
            {(props.itemDetailParentVariantArray.length > 0 && props.variantFlag == true && props.polymerFlag == true ? props.itemDetailParentVariantArray.map((item: any, index: any) => (
              <TableRow key={index}
                {...shouldHighlight && item.name === highlighted.name ? { ref: highlightedRef, selected: true } : {}}>
                {/* <TableCell>
                  {props.type === "Composite" && showLockIcon ? (
                    <Tooltip title="Restricted Substance">
                      <LockIcon style={{ fontSize: 16, color: 'red', fontWeight: 'bold', marginTop: 4, marginRight: "5%" }} />
                    </Tooltip>
                  ) : null}
                </TableCell> */}

                <TableCell>
                  <Typography className={classes.cell}>
                    <ItemIcon type="Substance" subType={item.nameType} isBackbone={item.Name === props.data.backboneIdentity ? true : false} />
                    {item.Type}
                  </Typography>
                </TableCell>
                <TableCell>{item.Name}</TableCell>
                {
                  item.isPrimaryForDomains && item.isPrimaryForDomains.length > 0 ?
                    <TableCell><span className={classes.bold}>{item.isPrimaryForDomains.join(", ")}</span>
                      {item.domains != null && item.domains.filter((val: any) => !item.isPrimaryForDomains.includes(val)).join(", ") ? <span>,&nbsp;</span>
                        : null
                      }
                      <span>{(item.isPrimaryForDomains != null ? ", " : "")} {item.Domains != null && item.Domains.filter((val: any) => !item.isPrimaryForDomains.includes(val)).join(", ")}</span>
                    </TableCell> :
                    <TableCell>{(item.Domains != null && item.Domains != undefined ? item.Domains.join(", ") : "")}</TableCell>
                }
                <TableCell>{item.Status}</TableCell>
                {// <TableCell className={substanceStatus === 'I' ? classes.blur : ''}>{item.nameType !== "SIR ID" && <EditButton itemData={substanceNameDetailToEditItem(item)} />}</TableCell>
                }
                {
                  // <TableCell className={substanceStatus === 'I' ? classes.blur : ''}>{item.nameType !== "CLS ID" && item.nameType !== "SIR ID" && <EditButton itemData={props.type === 'Classification' ? classificationNameDetailToEditItem(item) : substanceNameDetailToEditItem(item)} />}</TableCell>
                  // <TableCell>{(item.nameType !== "SIR ID" && substanceStatus !== 'I') && <SwapHorizIcon />}</TableCell>
                }
                {
                  //<TableCell>
                  //{item.nameType !== "SIR ID" ? item.status !== "I" ? (item.isPrimaryForDomains && item.isPrimaryForDomains.length === 0) ? <SwapHorizIcon /> : null : null : null}</TableCell>
                }
              </TableRow>

            )) : <TableRow><TableCell colSpan={5} align={'center'}>No Data Found</TableCell></TableRow>)
            }
          </TableBody>
        </Table></div> : "")}

    </div>
  )
  );
}

export default NameDetailList;
